/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKAvatar from "components/MKAvatar";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

function Intro() {
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container item xs={12} justifyContent="center" mx="auto">
          <Grid container justifyContent="space-around">
            <Grid item xs={12} md={12} mx={{ xs: "auto", sm: 6, md: 1 }}>
              <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                <MKTypography variant="h3">Welcome to Netsys Lab.</MKTypography>
              </MKBox>
              <MKTypography variant="body1" fontWeight="light" color="text">
	        Greetings! It is a pleasure to meet you. At the Networking Systems Lab, our focus is on collaborative research in the realms of <b>networks</b> and <b>systems</b>. Networks involve the communication between geographically separated computers, while systems encompass understanding their functionality and optimizing performance. In our lab, we endeavor to unravel the intricacies of networking within systems and enhance their performance. We also conduct research on networks and systems themselves. I welcome students interested in these fields to connect with us and explore opportunities for collaboration.
              </MKTypography>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Intro;
